<template>
    <div>
      <van-sticky :offset-top="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('门禁功能')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon v-if="paytype === 0 || paytype === 1" name="wechat-pay" size="18" />
              <van-icon v-if="paytype === 2" name="alipay" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
        <van-search v-model="termname" placeholder="请输入门禁名称" />
      </van-sticky>

      <van-grid  clickable column-num="3">
        <van-grid-item  v-for="(item) in lsTermList"
            :key="item.lockid"
            icon="lock" :text="item.termname" icon-color="#de0a24" @click="ShowOpenWindow(item)"/>

        </van-grid>
      <van-overlay :show="isLoading" z-index="9999999">
        <view class="wrapper">
          <view class="block" catch:tap="noop">
            <van-loading size="24px" color="#ffffff">{{loadcontent}}</van-loading>
          </view>
        </view>
      </van-overlay>
      <!-- 底部弹出 -->
        <van-popup
            round
            v-model:show="showRemote"
            position="bottom"
            :style="{ height: '30%' }"
        >
        <van-cell-group style="margin-top: 5px; margin-left:5px;margin-right: 5px;">
            <van-cell title="要操作的门" :value="curLockInfo.termname" />
         </van-cell-group>
         <van-cell-group align="center">
            <van-button :loading="isLoading" type="danger" block style="width: 80%;margin-bottom:20px;margin-top: 10px;" @click="RemoteOpen()">远程开门</van-button>
            <van-button v-if="isallowrepair===true" :loading="isLoading" type="success" block style="width: 80%" @click="RemoteRepairGrant()">修复授权</van-button>
         </van-cell-group>
          
        </van-popup>
        
    </div>
  </template>
  
  <script>
  import { Grid,GridItem,Search,Cell,CellGroup,Checkbox, Button, Dialog, CheckboxGroup, Card, SubmitBar, Toast, Tabs, Tab, Sticky, NavBar, Icon, Tag,  Overlay, Loading, Popup } from 'vant';
  import { requestGetGrantTermList, requestRemoteOpenDoor, requestRepairDoorGrant } from '../../api/api'
  
  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [Button.name]: Button,
      [Card.name]: Card,
      [Checkbox.name]: Checkbox,
      [SubmitBar.name]: SubmitBar,
      [CheckboxGroup.name]: CheckboxGroup,
      [Sticky.name]: Sticky,
      [NavBar.name]: NavBar,
      [Icon.name]: Icon,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Tag.name]:Tag,
      [Dialog.name]: Dialog,
      [Overlay.name]: Overlay,
      [Loading.name]: Loading,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Search.name]: Search,
      [Popup.name]: Popup
    },
    data() {
      return {
        showRemote: false,
        paytype: 3,
        termname: '',
        loadcontent: '加载中...',
        isLoading: false,
        active: 0,
        lsTermList: [],
        curLockInfo: {},
        isallowrepair: false
      }
    },
    mounted() {
      this.GetCurOrderInfo()
      
  
    },
    methods: {
    async RemoteRepairGrant() {
        Dialog.confirm({
          title: '修复确认',
          message: '您确认要修复' + this.curLockInfo.termname + ' 授权信息吗？确认之后，系统会重发您的授权！',
        })
          .then(async () => {
                this.loadcontent = '远程修复中...'
                this.isLoading = true
                const item = this.curLockInfo
                const res = await requestRepairDoorGrant({accesstoken: this.$store.state.accesstoken, lockid: item.lockid})
                if (res.return_code === '0') {
                    Toast({
                        message: res.return_message,
                        icon: 'success',
                    });
                    this.isLoading = false
        
                } else {
                    Toast({
                        message: res.return_message,
                        icon: 'fail',
                    });
                    this.isLoading = false
                }
            });
 
      },
      async RemoteOpen() {
        Dialog.confirm({
          title: '远程确认',
          message: '您确认要远程打开' + this.curLockInfo.termname + ' 吗？',
        })
          .then(async () => {
                this.loadcontent = '远程开门中...'
                this.isLoading = true
                const item = this.curLockInfo
                const res = await requestRemoteOpenDoor({accesstoken: this.$store.state.accesstoken, lockid: item.lockid})
                if (res.return_code === '0') {
                    Toast({
                        message: res.return_message,
                        icon: 'success',
                    });
                    this.isLoading = false
        
                } else {
                    Toast({
                        message: res.return_message,
                        icon: 'fail',
                    });
                    this.isLoading = false
                }
            });
 
      },
      ShowOpenWindow(item){
        this.isallowrepair = item.lockid.includes('cap2') === false && item.lockid.includes('cap1') === false
        this.curLockInfo = item
        this.showRemote = true

      },
      async GetCurOrderInfo () {
       
        this.lsTermList = []
        this.loadcontent = '加载中...'
        this.isLoading = true
        const res = await requestGetGrantTermList({ accesstoken: this.$store.state.accesstoken })
        console.log(res)
        if (res.return_code === '0') {
          this.lsTermList = res.rows
          this.isLoading = false
          if (res.rows.length === 0){
            Toast({
                message: '无数据!',
                icon: 'fail',
            });
          }
  
        } else {
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
            this.isLoading = false
        }
         
      }
    }
  };
  </script>
  
  <style lang="less">
  .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  .card-goods {
    padding: 10px 0;
    background-color: #fff;
  
    &__item {
      position: relative;
      background-color: #fafafa;
  
      .van-checkbox__label {
        width: 100%;
        height: auto; // temp
        padding: 0 10px 0 15px;
        box-sizing: border-box;
      }
  
      .van-checkbox__icon {
        top: 50%;
        left: 10px;
        z-index: 1;
        position: absolute;
        margin-top: -10px;
      }
  
      .van-card__price {
        color: #f44;
      }
    }
  }
  </style>
  